/* eslint-disable no-console */
import { notification } from 'antd';
import retailGlobal from '@retail/global';
import * as retailDotenv from '@retail/dotenv';
import { loadConfig } from '@retail/config';

import { ANTD_EXTERNAL_VERSION_LESS } from '~/constants/antd';

if (__IS_STANDALONE__) {
  require('antd/dist/antd.less');
  /**
   * TODO: antd/Datepicker doesn't add prefix for animation classes.
   * Importint pure css fixes it. The issue reproduces only in standalone mode.
   */
  require('antd/lib/date-picker/style/css');
}

const ENV = __IS_STANDALONE__
  ? process.env.ENV
  : retailGlobal.get<{ ENV?: string }>('__config')?.ENV;

loadConfig(retailDotenv.load(ENV));

notification.config({
  top: 70,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`
});
