export const LANGUAGES = Object.freeze({
  en: 'en',
  'en-gb': 'en',
  de: 'de-DE',
  'de-de': 'de-DE',
  es: 'es-ES',
  'es-se': 'es-ES',
  fr: 'fr-FR',
  'fr-fe': 'fr-FR',
  'fr-be': 'fr-BE',
  it: 'it-IT',
  'it-it': 'it-IT',
  nl: 'nl-NL',
  'nl-nl': 'nl-NL',
  'nl-be': 'nl-BE',
  pl: 'pl-PL',
  'pl-pl': 'pl-PL',
  de_at: 'de-AT',
  sv_se: 'sv-SE'
});
