import { PATHS } from '@retail/backoffice-clm-urls';
import { lazy } from 'react';
import { PathRouteProps } from 'react-router-dom';

const AutocenterSchedules = lazy(() => import('./pages/AutocenterSchedules'));

export const ROUTES: PathRouteProps[] = [
  {
    path: `${PATHS.OPPS}/autocenter-schedules`,
    element: <AutocenterSchedules />
  }
];
