import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import './setup';
import Root from './root.component';

function errorBoundary() {
  // Customize the root error boundary for your microfrontend here.
  return null;
}

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary
});
