/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getConf } from '@retail/config';
import { notification } from 'antd';
import Cookies from 'js-cookie';

import { getActivePlatform } from '../utils/platform';

const API_URL = getConf('API_URL');

const CLM_ROUTE_SECTION = 'clm';
const HTTP_LINK = createHttpLink({
  uri: () => {
    const { pathname } = location;
    const language = pathname.substring(
      0,
      pathname.indexOf(`/${CLM_ROUTE_SECTION}`)
    );

    return `${language}/${CLM_ROUTE_SECTION}/${API_URL}`;
  }
});

// NOTE Prepare common headers for all requests
const AUTH_LINK = setContext((operation, { headers }) => {
  // NOTE get the authentication access token from cookies if it exists
  const accessToken = Cookies.get('t');
  // NOTE return the headers to the context so httpLink can read them

  return {
    headers: {
      accept: 'application/json, text/plain, */*',
      'content-type': 'application/json;charset=UTF-8',
      authorization: `Bearer ${accessToken}`,
      'X-CLM-Platform': getActivePlatform(),
      ...headers
    }
  };
});

function handleErrors({ networkError, graphQLErrors, operation, forward }) {
  if (networkError?.statusCode === 401) {
    const accessToken = Cookies.get('t');

    operation.setContext({
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    });
    return forward(operation);
  }

  if (Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach(({ message }) => {
      if (message) {
        notification.error({ message });
      }
    });
  }
}

// NOTE Apollo client
export const GQL_CLIENT = new ApolloClient({
  link: ApolloLink.from([AUTH_LINK, onError(handleErrors), HTTP_LINK]),
  cache: new InMemoryCache({ typePolicies: {} }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only'
    },
    mutate: {
      awaitRefetchQueries: true
    }
  }
});
