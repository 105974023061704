import { getConf } from '@retail/config';

import { LANGUAGES } from './languages';

export const getLocale = (language: string) =>
  LANGUAGES[language] || LANGUAGES.en;

export function i18nDetectLng() {
  let language = localStorage.getItem(getConf('LANGUAGE_KEY'));

  if (!Object.keys(LANGUAGES).includes(language)) {
    language = getConf('LANGUAGE_KEY');
  }

  return language.toLowerCase();
}
